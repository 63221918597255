import { Fragment, useState, useEffect } from 'react';
import { Paper, Tabs, Tab, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const TabsNavigation = (props) => {
  const {
    tabs = [],
    width = 120,
    height = 30,
    paperWidth = 'auto',
    paperHeight = 'auto',
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState(tabs);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#777777',
      },
      secondary: {
        main: '#ffffff',
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: (props) => {
            if (props.className == 'MuiTabs-root') {
              props.children[2].props.children[0].props.children.forEach(
                (child, index) => {
                  child.props.children.props.sx.borderStyle = 'solid';
                  child.props.children.props.sx.borderWidth = 1;
                  child.props.children.props.sx.borderBottomWidth = 0;
                  child.props.children.props.sx.borderColor = '#e2e2e2';

                  if (index == 0) {
                    if (
                      props.children[2].props.children[0].props.children
                        .length == 1
                    ) {
                      child.props.children.props.sx.borderRadius =
                        '0.3rem 0.3rem 0rem 0rem';
                    } else {
                      child.props.children.props.sx.borderRight = 0;
                      child.props.children.props.sx.borderRadius =
                        '0.3rem 0rem 0rem 0rem';
                    }
                  } else if (
                    index ==
                    props.children[2].props.children[0].props.children.length -
                      1
                  ) {
                    child.props.children.props.sx.borderLeft = 0;
                    child.props.children.props.sx.borderColor = '#e2e2e2';
                    child.props.children.props.sx.borderRadius =
                      '0rem 0.3rem 0rem 0rem';
                  } else {
                    child.props.children.props.sx.borderRight = 0;
                    child.props.children.props.sx.borderLeft = 0;
                    child.props.children.props.sx.borderColor = '#e2e2e2';
                  }
                }
              );
            }
          },
        },
      },
    },
  });

  const tabStyles = (index) => {
    let minWidth = 50;
    return {
      width: width,
      minWidth: width <= minWidth ? width : minWidth,
      height: height,
      minHeight: height,
      background: tabIndex == index ? '#ffffff' : '#e2e2e2',
    };
  };

  useEffect(() => {
    setVisibleTabs(tabs.filter((tab) => !tab.hidden));
  }, [tabs]);

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={tabIndex}
        onChange={(event, newValue) => setTabIndex(newValue)}
        indicatorColor='transparent'
        variant='scrollable'
        scrollButtons='auto'
        sx={{ height: height, minHeight: height }}>
        {visibleTabs.map((tab, index) => (
          <Tooltip title={tab.tooltip ?? ''} key={index} arrow>
            <Tab
              label={tab.label ? tab.label : ''}
              icon={tab.icon ? tab.icon : null}
              iconPosition='start'
              sx={tabStyles(index)}
            />
          </Tooltip>
        ))}
      </Tabs>
      <Paper
        variant='outlined'
        sx={{
          borderRadius: '0rem 0.3rem 0.3rem 0.3rem',
          width: paperWidth,
          height: paperHeight,
        }}>
        {visibleTabs.map((tab, index) => {
          if (tabIndex == index)
            return <Fragment key={index}> {tab.component} </Fragment>;
        })}
      </Paper>
    </ThemeProvider>
  );
};

export default TabsNavigation;
