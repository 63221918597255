import { useContext } from 'react';
import { Button, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';

const DeviceButton = (props) => {
  const { device } = props;

  const { setError } = useContext(Context);

  const theme = useTheme();

  const styles = {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.alternative,
      borderRadius: 20,
      width: '6vw',
      height: '18vh',
      margin: '1vw',
    },
    label: {
      marginBottom: 10,
      fontSize: 15,
      fontWeight: 'bold',
      color: theme.palette.primary.alternative,
      cursor: 'default',
    },
    labelSwitch: {
      marginBottom: 12,
      fontSize: 15,
      fontWeight: 'bold',
      color: theme.palette.primary.alternative,
      cursor: 'default',
    },
    buttonStyleOn: {
      backgroundColor: theme.palette.primary.alternative,
      color: '#000',
      minWidth: 'unset',
      width: 45,
      height: 45,
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.alternative,
    },
    buttonStyleOff: {
      backgroundColor: '#000',
      color: theme.palette.primary.alternative,
      minWidth: 'unset',
      width: 45,
      height: 45,
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.alternative,
    },
  };

  const handleStatusDevice = () => {
    let url = 'devices/change_status';
    let params = {
      deviceId: device.deviceId,
      status: !device.status,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        return setError('EXCEPTION');
      }

      if (!response.success) {
        return setError(response.errorMsg);
      }
    });
  };

  return (
    <>
      {device.type == 'button' && (
        <div style={styles.buttonContainer}>
          <div style={styles.label}> {device.name} </div>
          <Button
            variant='contained'
            sx={device.status ? styles.buttonStyleOn : styles.buttonStyleOff}
            onClick={handleStatusDevice}>
            {device.status ? 'On' : 'Off'}
          </Button>
        </div>
      )}
      {device.type == 'switch' && (
        <div style={styles.buttonContainer}>
          <div style={styles.labelSwitch}> {device.name} </div>
          <Switch
            checked={device.status}
            onChange={handleStatusDevice}
            sx={{
              '& .MuiSwitch-track': {
                backgroundColor: device.status
                  ? theme.palette.primary.alternative
                  : '#767577',
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: device.status
                  ? theme.palette.primary.alternative
                  : '#f4f3f4',
              },
              marginBottom: 0.5,
            }}
          />
        </div>
      )}
    </>
  );
};

export default DeviceButton;
