import D01 from '../assets/weather-icons/01d.png';
import D02 from '../assets/weather-icons/02d.png';
import D03 from '../assets/weather-icons/03d.png';
import D04 from '../assets/weather-icons/04d.png';
import D09 from '../assets/weather-icons/09d.png';
import D10 from '../assets/weather-icons/10d.png';
import D11 from '../assets/weather-icons/11d.png';
import D13 from '../assets/weather-icons/13d.png';
import D50 from '../assets/weather-icons/50d.png';
import N01 from '../assets/weather-icons/01n.png';
import N02 from '../assets/weather-icons/02n.png';
import N03 from '../assets/weather-icons/03n.png';
import N04 from '../assets/weather-icons/04n.png';
import N09 from '../assets/weather-icons/09n.png';
import N10 from '../assets/weather-icons/10n.png';
import N11 from '../assets/weather-icons/11n.png';
import N13 from '../assets/weather-icons/13n.png';
import N50 from '../assets/weather-icons/50n.png';

export const getWeatherIcon = (icon, description) => {
  const icons = {
    '01d': D01,
    '02d': D02,
    '03d': D03,
    '04d': D04,
    '09d': D09,
    '10d': D10,
    '11d': D11,
    '13d': D13,
    '50d': D50,
    '01n': N01,
    '02n': N02,
    '03n': N03,
    '04n': N04,
    '09n': N09,
    '10n': N10,
    '11n': N11,
    '13n': N13,
    '50n': N50,
  };

  return <img src={icons[icon]} alt={description} />;
};
