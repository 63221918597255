import { useState, useEffect, useContext } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../../components/modal-form/ModalForm';

const DevicesModal = (props) => {
  const {
    open,
    setOpen,
    action,
    setAction,
    categoryOptions,
    typeOptions,
    arduinoPinOptions,
    rowSelected,
    fetchData,
  } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    headerTitle: '',
    name: '',
    category: null,
    type: null,
    arduinoPin: null,
    device_order: '',
  };

  const [modalData, setModalData] = useState(initialModalData);

  const resetForm = () => {
    setAction('');
    setModalData(initialModalData);
    setShowLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    if (event.target.type == 'number' && Number(event.target.value) < 0) {
      setModalData({
        ...modalData,
        [event.target.id]: 0,
      });
    } else {
      setModalData({
        ...modalData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleSubmit = () => {
    for (const field in modalData) {
      if (!modalData[field] || modalData[field] == '') {
        setError('Debe completar todos los campos.');
        return;
      }
    }

    setShowLoading(true);

    let url = `devices/${action == 'edit' ? 'update' : 'create'}`;
    let params = {
      name: modalData.name,
      category: modalData.category.value,
      type: modalData.type.value,
      arduinoPin: modalData.arduinoPin.value,
      device_order: modalData.device_order,
    };

    if (action == 'edit') {
      params.deviceId = rowSelected.deviceId;
    }

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('EXCEPTION');
      }

      if (response.success) {
        resetForm();
        fetchData();
        return setSuccess(
          `El dispositivo ha sido ${
            action == 'edit' ? 'actualizado' : 'creado'
          } exitosamente.`
        );
      } else {
        resetForm();
        return setError(response.errorMsg);
      }
    });
  };

  useEffect(() => {
    if (action == 'edit') {
      let categoryValue = categoryOptions.find(
        (option) => option.value == rowSelected.category
      );

      let arduinoPinValue = arduinoPinOptions.find(
        (option) => option.value == rowSelected.arduinoPin
      );

      let typeValue = typeOptions.find(
        (option) => option.value == rowSelected.type
      );

      setModalData({
        headerTitle: 'Editar dispositivo',
        name: rowSelected.name,
        category: categoryValue,
        type: typeValue,
        arduinoPin: arduinoPinValue,
        device_order: rowSelected.device_order,
      });
    } else {
      setModalData({
        headerTitle: 'Agregar dispositivo',
        name: '',
        category: null,
        type: null,
        arduinoPin: null,
        device_order: '',
      });
    }
  }, [action]);

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={modalData.headerTitle}
      onModalSubmit={handleSubmit}>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        <TextField
          id='name'
          label='Nombre de dispositivo'
          color='primary'
          value={modalData.name}
          onChange={handleChange}
          style={{ flex: 1, margin: 10 }}
          autoComplete='off'
        />
        <Autocomplete
          id='category'
          options={categoryOptions}
          value={modalData.category}
          onChange={(event, newValue) =>
            setModalData({ ...modalData, category: newValue })
          }
          style={{ flex: 1, margin: 10 }}
          renderInput={(params) => <TextField {...params} label='Categoría' />}
          isOptionEqualToValue={(option, selected) =>
            option.value == selected || option.value == selected?.value
          }
        />
        <Autocomplete
          id='type'
          options={typeOptions}
          value={modalData.type}
          onChange={(event, newValue) =>
            setModalData({ ...modalData, type: newValue })
          }
          style={{ flex: 1, margin: 10 }}
          renderInput={(params) => <TextField {...params} label='Tipo' />}
          isOptionEqualToValue={(option, selected) =>
            option.value == selected || option.value == selected?.value
          }
        />
        <Autocomplete
          id='arduinoPin'
          options={arduinoPinOptions}
          value={modalData.arduinoPin}
          onChange={(event, newValue) =>
            setModalData({ ...modalData, arduinoPin: newValue })
          }
          style={{ flex: 1, margin: 10 }}
          renderInput={(params) => (
            <TextField {...params} label='Pin arduino' />
          )}
          isOptionEqualToValue={(option, selected) =>
            option.value == selected || option.value == selected?.value
          }
        />
        <TextField
          id='device_order'
          label='Orden'
          color='primary'
          value={modalData.device_order}
          onChange={handleChange}
          style={{ flex: 1, margin: 10 }}
          autoComplete='off'
          type='number'
        />
      </div>
    </ModalForm>
  );
};

export default DevicesModal;
