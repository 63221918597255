import { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
import { Switch } from '@mui/material';
import { config } from '../../config';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import DevicesModal from './DevicesModal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Devices = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const socket = io(config.socketUrl);

  const typeOptions = [
    { value: 'button', label: 'Botón' },
    { value: 'switch', label: 'Switch' },
  ];

  const [rowSelected, setRowSelected] = useState({});
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [arduinoPinOptions, setArduinoPinOptions] = useState([]);
  const [confirmDeleteDevice, setConfirmDeleteDevice] = useState(false);
  const [confirmStatusDevice, setConfirmStatusDevice] = useState(false);
  const [action, setAction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'deviceId',
      headerName: 'Identificador',
    },
    {
      field: 'name',
      headerName: 'Nombre',
    },
    {
      field: 'category',
      headerName: 'Categoría',
      onRenderCell: (row) => {
        let category = categoryOptions.filter(
          (option) => option.value == row.category
        );
        return category[0]?.label;
      },
    },
    {
      field: 'type',
      headerName: 'Tipo',
      onRenderCell: (row) => {
        let type = typeOptions.filter((option) => option.value == row.type);
        return type[0]?.label;
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      onRenderCell: (row) => (
        <Switch
          checked={row.status}
          title={row.status == true ? 'Encendido' : 'Apagado'}
          onChange={() => {
            setRowSelected(row);
            setConfirmStatusDevice(true);
          }}
        />
      ),
    },
    {
      field: 'arduinoPin',
      headerName: 'Pin arduino',
      onRenderCell: (row) => {
        let arduinoPin = arduinoPinOptions.filter(
          (option) => option.value == row.arduinoPin
        );
        return arduinoPin[0]?.label;
      },
    },
    {
      field: 'device_order',
      headerName: 'Orden',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      actions: [
        {
          icon: <EditIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setAction('edit');
            setOpenModal(true);
          },
          tooltip: 'Editar',
        },
        {
          icon: <DeleteIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setConfirmDeleteDevice(true);
          },
          tooltip: 'Eliminar',
        },
      ],
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'devices/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setRows(response.data);
      }

      setShowLoading(false);
    });
  };

  const fetchCategories = () => {
    setShowLoading(true);

    let url = 'categories/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        let options = response.data.map((category) => ({
          value: category.name,
          label: category.label,
        }));

        setCategoryOptions(options);
      }

      setShowLoading(false);
    });
  };

  const handleStatusDevice = () => {
    setShowLoading(true);

    let url = 'devices/change_status';
    let params = {
      deviceId: rowSelected.deviceId,
      status: !rowSelected.status,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setConfirmStatusDevice(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setShowLoading(false);
        setConfirmStatusDevice(false);
        return setSuccess('El dispositivo ha sido actualizado exitosamente.');
      } else {
        setShowLoading(false);
        setConfirmStatusDevice(false);
        return setError(response.errorMsg);
      }
    });
  };

  const handleDeleteDevice = () => {
    setShowLoading(true);

    let url = 'devices/delete';
    let params = {
      deviceId: rowSelected.deviceId,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setConfirmDeleteDevice(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setShowLoading(false);
        setConfirmDeleteDevice(false);
        fetchData();
        return setSuccess('El dispositivo ha sido eliminado exitosamente.');
      } else {
        setShowLoading(false);
        setConfirmDeleteDevice(false);
        return setError(response.errorMsg);
      }
    });
  };

  useEffect(() => {
    fetchData();
    fetchCategories();

    let options = [];

    for (let i = 0; i <= 15; i++) {
      options.push({ value: `A${i}`, label: `Analog ${i}` });
    }
    for (let i = 0; i <= 53; i++) {
      options.push({ value: `${i}`, label: `Digital ${i}` });
    }

    setArduinoPinOptions(options);

    socket.on('device_status_changed', (response) => setRows(response.message));

    return () => socket.disconnect();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Dispositivos </h2>
      <Grid
        columns={columns}
        rows={rows}
        onAddButtonClick={() => {
          setAction('add');
          setOpenModal(true);
        }}
      />

      <DevicesModal
        open={openModal}
        setOpen={setOpenModal}
        action={action}
        setAction={setAction}
        categoryOptions={categoryOptions}
        typeOptions={typeOptions}
        arduinoPinOptions={arduinoPinOptions}
        rowSelected={rowSelected}
        fetchData={fetchData}
      />

      <DialogConfirm
        open={confirmStatusDevice}
        setOpen={setConfirmStatusDevice}
        description='¿Desea actualizar el dispositivo?'
        onConfirm={handleStatusDevice}
      />

      <DialogConfirm
        open={confirmDeleteDevice}
        setOpen={setConfirmDeleteDevice}
        description='¿Desea eliminar el dispositivo?'
        onConfirm={handleDeleteDevice}
      />
    </div>
  );
};

export default Devices;
