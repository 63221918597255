export const config = {
  // dev
  // port: 5000,
  // apiBaseUrl: 'http://localhost:5000/api/',
  // socketUrl: 'http://192.168.0.152:5000',
  
  // prod
  port: 5000,
  apiBaseUrl: '/api/',
  socketUrl: '/',
};
