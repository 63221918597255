import { useState, useEffect, useContext } from 'react';
import { TextField, Switch, InputAdornment, IconButton } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../../components/modal-form/ModalForm';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UsersModal = (props) => {
  const { open, setOpen, fetchData } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    headerTitle: 'Agregar usuario',
    username: '',
    password: '',
    password_repeat: '',
    root: false,
  };

  const [modalData, setModalData] = useState(initialModalData);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const resetForm = () => {
    setModalData(initialModalData);
    setShowLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setModalData({
      ...modalData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = () => {
    for (const field in modalData) {
      if (modalData[field] == '' && field != 'root') {
        setError('Debe completar todos los campos.');
        return;
      }
    }

    if (modalData.password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres de longitud.');
      return;
    }

    if (modalData.password != modalData.password_repeat) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    setShowLoading(true);

    let url = 'users/create';
    let params = {
      username: modalData.username,
      password: modalData.password,
      root: modalData.root,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('EXCEPTION');
      }

      if (response.success) {
        resetForm();
        fetchData();
        return setSuccess('El usuario ha sido creado exitosamente.');
      } else {
        resetForm();
        return setError(response.errorMsg);
      }
    });
  };

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={modalData.headerTitle}
      onModalSubmit={handleSubmit}>
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <TextField
            id='username'
            label='Nombre de usuario'
            color='primary'
            value={modalData.username}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
          <TextField
            id='password'
            label='Contraseña'
            color='primary'
            value={modalData.password}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id='password_repeat'
            label='Repetir contraseña'
            color='primary'
            value={modalData.password_repeat}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            type={showPasswordRepeat ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    edge='end'>
                    {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ padding: 10 }}>
          ¿Puede gestionar usuarios?
          <Switch
            checked={modalData.root}
            onChange={(event) => {
              setModalData((prevState) => ({
                ...prevState,
                root: event.target.checked,
              }));
            }}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default UsersModal;
