import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';
import { ContextProvider } from './context/Context';
import Router from './router/Router';
import Alerts from './components/alerts/Alerts';
import Loading from './components/loading/Loading';

const App = () => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#22BEAA',
          alternative: '#2affe4',
          alternative2: '#aafef4',
        },
        secondary: {
          main: '#2b3332',
          alternative: '#455150',
          alternative2: '#333333',
        },
      },
      components: {
        MuiTablePagination: {
          styleOverrides: {
            selectLabel: {
              marginTop: 'revert',
            },
            displayedRows: {
              marginTop: 'revert',
            },
          },
        },
      },
    },
    esES
  );

  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <Router />
        <Alerts />
        <Loading />
      </ContextProvider>
    </ThemeProvider>
  );
};

export default App;
