import { createContext, useState } from 'react';

export const Context = createContext({});

export const ContextProvider = ({ children }) => {
  const [values, setValues] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    severity: 'success',
    text: '',
    duration: 3000,
    open: false,
  });

  const resetForm = () => {
    setValues({});
  };

  const setOneValue = (id, value) => {
    setValues({
      ...values,
      [id]: value,
    });
  };

  const setSuccess = (text, duration = 3000) => {
    setShowAlert({
      severity: 'success',
      text: text,
      duration: duration,
      open: true,
    });
  };

  const setError = (text, duration = 3000) => {
    setShowAlert({
      severity: 'error',
      text: text,
      duration: duration,
      open: true,
    });
  };

  const value = {
    values,
    setValues,
    resetForm,
    setOneValue,
    showAlert,
    setShowAlert,
    setSuccess,
    setError,
    showLoading,
    setShowLoading,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
