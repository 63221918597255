import { useEffect, useState, useContext } from 'react';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import CategoriesModal from './CategoriesModal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Categories = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const [usedCategories, setUsedCategories] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const [action, setAction] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'categoryId',
      headerName: 'Identificador',
    },
    {
      field: 'name',
      headerName: 'Nombre',
    },
    {
      field: 'label',
      headerName: 'Título',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      actions: [
        {
          icon: <EditIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setAction('edit');
            setOpenModal(true);
          },
          tooltip: 'Editar',
        },
        {
          icon: <DeleteIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setConfirmDeleteCategory(true);
          },
          tooltip: 'Eliminar',
          disabled: (row) => usedCategories.includes(row.name),
        },
      ],
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'categories/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setRows(response.data);
      }

      setShowLoading(false);
    });
  };

  const fetchDevices = () => {
    setShowLoading(true);

    let url = 'devices/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        let categories = [];

        response.data.forEach((device) => {
          if (!categories.includes(device.category)) {
            categories.push(device.category);
          }
        });

        setUsedCategories(categories);
      }

      setShowLoading(false);
    });
  };

  const handleDeleteCategory = () => {
    setShowLoading(true);

    let url = 'categories/delete';
    let params = {
      categoryId: rowSelected.categoryId,
      name: rowSelected.name,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setConfirmDeleteCategory(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setShowLoading(false);
        setConfirmDeleteCategory(false);
        fetchData();
        return setSuccess('La categoría ha sido eliminada exitosamente.');
      } else {
        setShowLoading(false);
        setConfirmDeleteCategory(false);
        return setError(response.errorMsg);
      }
    });
  };

  useEffect(() => {
    fetchData();
    fetchDevices();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Categorías </h2>
      <Grid
        columns={columns}
        rows={rows}
        onAddButtonClick={() => {
          setAction('add');
          setOpenModal(true);
        }}
      />

      <CategoriesModal
        open={openModal}
        setOpen={setOpenModal}
        action={action}
        setAction={setAction}
        rowSelected={rowSelected}
        fetchData={fetchData}
      />

      <DialogConfirm
        open={confirmDeleteCategory}
        setOpen={setConfirmDeleteCategory}
        description='¿Desea eliminar la categoría?'
        onConfirm={handleDeleteCategory}
      />
    </div>
  );
};

export default Categories;
