import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { analyzeSpeech } from './vocabulary';

const SpeechRecognition = (props) => {
  const { devices } = props;

  const recognition = new window.webkitSpeechRecognition();

  const buttonTheme = createTheme({
    palette: {
      primary: {
        main: '#2affe4',
      },
    },
  });

  const [transcript, setTranscript] = useState('');
  const [listening, setListening] = useState(false);

  useEffect(() => {
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          setTranscript(
            (prevTranscript) => prevTranscript + event.results[i][0].transcript
          );
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
    };

    if (listening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [listening]);

  useEffect(() => {
    analyzeSpeech(transcript, devices, () => setTranscript(''));
  }, [transcript]);

  return (
    <ThemeProvider theme={buttonTheme}>
      <IconButton title='hola' onClick={() => setListening(!listening)}>
        {listening ? (
          <MicOffIcon color='primary' />
        ) : (
          <MicIcon color='primary' />
        )}
      </IconButton>
    </ThemeProvider>
  );
};

export default SpeechRecognition;
