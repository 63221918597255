import { useState, useContext } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../modal-form/ModalForm';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { logout } from '../../utils/helpers';

const PasswordModal = (props) => {
  const { open, setOpen, setAnchorEl } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    password: '',
    password_repeat: '',
  };

  const [modalData, setModalData] = useState(initialModalData);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const resetForm = () => {
    setShowPassword(false);
    setShowPasswordRepeat(false);
    setModalData(initialModalData);
    setShowLoading(false);
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (event) => {
    setModalData({
      ...modalData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (modalData.password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres de longitud.');
      return;
    }

    if (modalData.password != modalData.password_repeat) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    setShowLoading(true);

    let url = 'users/change_password';
    let params = {
      password: modalData.password,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('EXCEPTION');
      }

      if (response.success) {
        setAnchorEl(null);
        setOpen(false);
        setSuccess('La contraseña ha sido cambiada exitosamente.');
        setTimeout(logout, 3000);
      } else {
        resetForm();
        return setError(response.errorMsg);
      }
    });
  };

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={'Cambiar contraseña'}
      onModalSubmit={handleSubmit}>
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <TextField
            id='password'
            label='Nueva contraseña'
            color='primary'
            value={modalData.password}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id='password_repeat'
            label='Repetir nueva contraseña'
            color='primary'
            value={modalData.password_repeat}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
            type={showPasswordRepeat ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    edge='end'>
                    {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default PasswordModal;
