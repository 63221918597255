export const alertMessage = (msg) => {
  const defaultMessage = 'Ha ocurrido un error.';
  const serverErrorMessage = 'La operación ha fallado.';

  const messages = {
    EXCEPTION: serverErrorMessage,
    INVALID_PARAMS: defaultMessage,
    INTERNAL_ERROR: defaultMessage,
    INVALID_CREDENTIALS: 'Credenciales de usuario inválidas.',
    CREATE_SESSION_ERROR: 'La sesión no ha sido creada.',
    INVALID_USER: 'Usuario inválido.',
    PERMISSION_DENIED: 'Permiso denegado.',
    CREATE_PASSWORD_ERROR: 'El usuario no ha sido creado.',
    USERNAME_ALREADY_EXIST: 'El nombre de usuario ingresado ya existe.',
    ARDUINOPIN_ALREADY_EXIST: 'El pin de arduino ingresado ya está en uso.',
    CATEGORY_ALREADY_EXIST: 'La categoría ingresada ya está en uso.',
    CATEGORY_IN_USE: 'La categoría está siendo usada por al menos un dispositivo.',
  };

  return messages[msg] ?? msg;
};
