import { useState, useEffect, useContext } from 'react';
import { TextField } from '@mui/material';
import { restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import ModalForm from '../../components/modal-form/ModalForm';

const CategoriesModal = (props) => {
  const { open, setOpen, action, setAction, rowSelected, fetchData } = props;

  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const initialModalData = {
    headerTitle: '',
    name: '',
    label: '',
  };

  const [modalData, setModalData] = useState(initialModalData);

  const resetForm = () => {
    setAction('');
    setModalData(initialModalData);
    setShowLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setModalData({
      ...modalData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = () => {
    for (const field in modalData) {
      if (!modalData[field] || modalData[field] == '') {
        setError('Debe completar todos los campos.');
        return;
      }
    }

    setShowLoading(true);

    let url = `categories/${action == 'edit' ? 'update' : 'create'}`;
    let params = {
      name: modalData.name,
      label: modalData.label,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        resetForm();
        return setError('EXCEPTION');
      }

      if (response.success) {
        resetForm();
        fetchData();
        return setSuccess(
          `La categoría ha sido ${
            action == 'edit' ? 'actualizada' : 'creada'
          } exitosamente.`
        );
      } else {
        resetForm();
        return setError(response.errorMsg);
      }
    });
  };

  useEffect(() => {
    if (action == 'edit') {
      setModalData({
        headerTitle: 'Editar categoría',
        name: rowSelected.name,
        label: rowSelected.label,
      });
    } else {
      setModalData({
        headerTitle: 'Agregar categoría',
        name: '',
        label: '',
      });
    }
  }, [action]);

  return (
    <ModalForm
      open={open}
      resetForm={resetForm}
      headerTitle={modalData.headerTitle}
      onModalSubmit={handleSubmit}>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {action == 'add' && (
          <TextField
            id='name'
            label='Nombre de categoría'
            color='primary'
            value={modalData.name}
            onChange={handleChange}
            style={{ flex: 1, margin: 10 }}
            autoComplete='off'
          />
        )}
        <TextField
          id='label'
          label='Título de categoría'
          color='primary'
          value={modalData.label}
          onChange={handleChange}
          style={{ flex: 1, margin: 10 }}
          autoComplete='off'
        />
      </div>
    </ModalForm>
  );
};

export default CategoriesModal;
