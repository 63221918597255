import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DialogConfirm = (props) => {
  const { open, setOpen, description, onConfirm } = props;

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle> {description} </DialogTitle>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
          }}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: '#ffffff',
              marginTop: 2,
              width: 100,
            }}
            onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: '#ffffff',
              marginTop: 2,
              width: 100,
            }}
            onClick={onConfirm}>
            Aceptar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
