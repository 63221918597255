import { useEffect, useState, useContext } from 'react';
import { Switch } from '@mui/material';
import { restGet, restPost } from '../../rest/rest';
import { Context } from '../../context/Context';
import Grid from '../../components/grid/Grid';
import DialogConfirm from '../../components/dialog-confirm/DialogConfirm';
import UsersModal from './UsersModal';
import DeleteIcon from '@mui/icons-material/Delete';

const Users = () => {
  const { setSuccess, setError, setShowLoading } = useContext(Context);

  const [rowSelected, setRowSelected] = useState({});
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [confirmRootUser, setConfirmRootUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'userId',
      headerName: 'Identificador',
    },
    {
      field: 'username',
      headerName: 'Nombre',
    },
    {
      field: 'root',
      headerName: 'Gestiona usuarios',
      onRenderCell: (row) => (
        <Switch
          checked={row.root}
          title={row.root == true ? 'Si' : 'No'}
          onChange={() => {
            setRowSelected(row);
            setConfirmRootUser(true);
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      actions: [
        {
          icon: <DeleteIcon />,
          onClick: (row) => {
            setRowSelected(row);
            setConfirmDeleteUser(true);
          },
          tooltip: 'Eliminar',
          disabled: (row) => row.username == sessionStorage.getItem('username'),
        },
      ],
    },
  ];

  const fetchData = () => {
    setShowLoading(true);

    let url = 'users/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setRows(response.data);
      }

      setShowLoading(false);
    });
  };

  const handleRootUser = () => {
    setShowLoading(true);

    let url = 'users/change_root';
    let params = {
      userId: rowSelected.userId,
      root: !rowSelected.root,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setConfirmRootUser(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setShowLoading(false);
        setConfirmRootUser(false);
        fetchData();
        return setSuccess('El usuario ha sido actualizado exitosamente.');
      } else {
        setShowLoading(false);
        setConfirmRootUser(false);
        return setError(response.errorMsg);
      }
    });
  };

  const handleDeleteUser = () => {
    setShowLoading(true);

    let url = 'users/delete';
    let params = {
      userId: rowSelected.userId,
    };

    restPost(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        setConfirmDeleteUser(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setShowLoading(false);
        setConfirmDeleteUser(false);
        fetchData();
        return setSuccess('El usuario ha sido eliminado exitosamente.');
      } else {
        setShowLoading(false);
        setConfirmDeleteUser(false);
        return setError(response.errorMsg);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h2> Usuarios </h2>
      <Grid
        columns={columns}
        rows={rows}
        onAddButtonClick={() => setOpenModal(true)}
      />

      <UsersModal
        open={openModal}
        setOpen={setOpenModal}
        fetchData={fetchData}
      />

      <DialogConfirm
        open={confirmRootUser}
        setOpen={setConfirmRootUser}
        description='¿Desea actualizar el usuario?'
        onConfirm={handleRootUser}
      />

      <DialogConfirm
        open={confirmDeleteUser}
        setOpen={setConfirmDeleteUser}
        description='¿Desea eliminar el usuario?'
        onConfirm={handleDeleteUser}
      />
    </div>
  );
};

export default Users;
