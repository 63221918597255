import axios from 'axios';
import { config } from '../config';

const rest = (method = 'get', url = '', params = {}, callback = false) => {
  const apiBaseUrl = config.apiBaseUrl;

  params.validation = {
    token: sessionStorage.getItem('token'),
    username: sessionStorage.getItem('username'),
  };

  let axiosParams = {
    method: method,
    url: apiBaseUrl + url,
  };

  let configHeaders = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
    },
  };

  if (method == 'get') {
    axiosParams.params = params;
  } else {
    axiosParams.data = params;
  }

  axios(axiosParams, configHeaders)
    .then((response) => {
      if (callback !== false) {
        return callback(response.data);
      }
    })
    .catch((error) => {
      let response = {
        exception: true,
        error: error,
      };

      if (callback !== false) {
        return callback(response);
      }
    });
};

export const restGet = (url, params, callback) =>
  rest('get', url, params, callback);
export const restPost = (url, params, callback) =>
  rest('post', url, params, callback);
