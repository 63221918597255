import { restPost } from '../../rest/rest';

const keywords = {
  on: [
    'encender',
    'encendes',
    'enciendes',
    'encenderias',
    'encenderías',
    'prender',
    'prendes',
    'prenderias',
    'prenderías',
  ],
  off: ['apagar', 'apagas', 'apagarias', 'apagarías'],
  devices: [
    'cocina',
    'isla',
    'pasillo',
    'estudio',
    'deco',
    'living',
    'comedor',
    'patio',
    'extractor',
    'termotanque',
  ],
};

export const analyzeSpeech = (text, devices, resetText) => {
  let value = 'unknown';
  let deviceName = 'unknown';

  for (let i = 0; i < keywords.on.length; i++) {
    if (text.includes(keywords.on[i])) {
      value = 'on';
      break;
    }
  }

  if (value == 'unknown') {
    for (let i = 0; i < keywords.off.length; i++) {
      if (text.includes(keywords.off[i])) {
        value = 'off';
        break;
      }
    }
  }

  if (value == 'unknown') {
    resetText();
    return;
  }

  for (let i = 0; i < keywords.devices.length; i++) {
    if (text.includes(keywords.devices[i])) {
      deviceName = keywords.devices[i];
      break;
    }
  }

  if (deviceName == 'unknown') {
    resetText();
    return;
  }

  let device = devices.filter(
    (device) => device.name.toLowerCase() == deviceName
  );

  if (device.length != 1) {
    resetText();
    return;
  }

  let url = 'devices/change_status';
  let params = {
    deviceId: device[0].deviceId,
    status: value == 'on' ? true : false,
  };

  restPost(url, params, () => resetText());
};
