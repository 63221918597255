import { useEffect, useState } from 'react';
import { getWeatherIcon } from '../../utils/weatherIcons';

const Weather = () => {
  const [weatherData, setWeatherData] = useState([]);

  const fetchData = async () => {
    const openWeatherMap = {
      lat: '-34.870052',
      lon: '-58.105223',
      appid: 'e7d90b3b2d7f3a35c5a43edc164f34dd',
      units: 'metric',
      lang: 'es',
    };

    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${openWeatherMap.lat}&lon=${openWeatherMap.lon}&units=${openWeatherMap.units}&lang=${openWeatherMap.lang}&appid=${openWeatherMap.appid}`;

    var data = await (await fetch(url)).json();

    setWeatherData(data.list.slice(0, 5));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {weatherData.map((data) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <div>{data.dt_txt.split(' ')[1]}</div>
          <div>
            {getWeatherIcon(data.weather[0].icon, data.weather[0].description)}
          </div>
          <div>{data.main.temp.toString().substring(0, 4)}°C</div>
          <div>{data.main.humidity}%</div>
        </div>
      ))}
    </>
  );
};

export default Weather;
