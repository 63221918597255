import { useEffect, useContext } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { Context } from '../../context/Context';
import { alertMessage } from './AlertMessage';

const Alerts = () => {
  const { showAlert, setShowAlert } = useContext(Context);

  const handleClose = () => {
    setShowAlert({
      ...showAlert,
      open: false,
    });
  };

  useEffect(() => {
    if (showAlert.open) {
      setTimeout(handleClose, showAlert.duration);
    }
  }, [showAlert]);

  return (
    <Snackbar
      open={showAlert.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        severity={showAlert.severity}
        variant='filled'
        onClose={handleClose}>
        <AlertTitle>
          {showAlert.severity.substring(0, 1).toUpperCase() +
            showAlert.severity.substring(1)}
        </AlertTitle>
        {alertMessage(showAlert.text)}
      </Alert>
    </Snackbar>
  );
};

export default Alerts;
