import { Route, Routes } from 'react-router-dom';
import Login from '../views/login/Login';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import Devices from '../views/devices/Devices';
import Categories from '../views/categories/Categories';
import Users from '../views/users/Users';
import Registers from '../views/registers/Registers';
import Panel from '../views/panel/Panel';

const Router = () => {
  const token = sessionStorage.getItem('token');
  const root = sessionStorage.getItem('root') == 'true' ? true : false;

  return (
    <>
      {!token ? (
        <Login />
      ) : (
        <div style={{ overflowY: 'hidden' }}>
          {window.location.pathname == '/panel' ? (
            <Panel />
          ) : (
            <>
              <Header />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <div
                  style={{ width: '80vw', height: '90vh', overflowY: 'auto' }}>
                  <Routes>
                    <Route path={'/'} element={<Devices />} />
                    <Route path={'/devices'} element={<Devices />} />
                    <Route path={'/categories'} element={<Categories />} />
                    {root && <Route path={'/users'} element={<Users />} />}
                    {root && (
                      <Route path={'/registers'} element={<Registers />} />
                    )}
                  </Routes>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Router;
