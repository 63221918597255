import { useEffect, useState, useContext } from 'react';
import { restGet } from '../../rest/rest';
import { Context } from '../../context/Context';

const Terminal = (props) => {
  const { page } = props;

  const { setError, setShowLoading } = useContext(Context);

  const [data, setData] = useState([]);

  const fetchData = () => {
    setShowLoading(true);

    let url = 'registers/list';
    let params = {
      fileName: page,
    };

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setData(response.data.reverse());
      }

      setShowLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        color: '#fff',
        backgroundColor: '#000',
        borderRadius: '0rem 0.3rem 0.3rem 0.3rem',
        padding: 10,
        overflowY: 'auto',
        height: '100%',
      }}>
      {data.map((line, index) => (
        <div key={index} style={{ marginBottom: 5 }}>
          {line}
        </div>
      ))}
    </div>
  );
};

export default Terminal;
