import TabsNavigation from '../../components/tabs-navigation/TabsNavigation';
import Terminal from './Terminal';

const Registers = () => {
  const tabs = [
    {
      label: 'Dispositivos',
      component: <Terminal page={'devices'} />,
    },
    {
      label: 'Categorías',
      component: <Terminal page={'categories'} />,
    },
    {
      label: 'Usuarios',
      component: <Terminal page={'users'} />,
    },
    {
      label: 'Tramas',
      component: <Terminal page={'traces'} />,
    },
    {
      label: 'Login',
      component: <Terminal page={'login'} />,
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <TabsNavigation
        tabs={tabs}
        width={150}
        height={40}
        paperHeight={'75vh'}
      />
    </div>
  );
};

export default Registers;
