import { useEffect, useState, useContext } from 'react';
import { Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import io from 'socket.io-client';
import { restGet } from '../../rest/rest';
import { config } from '../../config';
import { Context } from '../../context/Context';
import DeviceButton from '../../components/device-button/DeviceButton';
import SpeechRecognition from '../../components/speech-recognition/SpeechRecognition';
import Weather from './Weather';
import Background from '../../assets/panel-background.png';

const Panel = () => {
  const { setError, setShowLoading } = useContext(Context);

  const socket = io(config.socketUrl);

  const theme = useTheme();

  const styles = {
    container: {
      contain: 'content',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100vh',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      backgroundColor: '#2b3332',
      backgroundRepeat: 'no-repeat',
      overflowY: 'auto',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 10,
      margin: 10,
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.alternative,
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cardTitle: {
      textAlign: 'center',
      marginBottom: 10,
      color: theme.palette.primary.alternative,
      fontSize: 20,
    },
    cardBody: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    traceCard: {
      display: 'flex',
      flex: 1,
      color: '#fff',
      backgroundColor: '#000',
      borderColor: theme.palette.primary.alternative,
      borderStyle: 'solid',
      borderWidth: 1,
      overflowY: 'auto',
      maxHeight: '40vh',
    },
  };

  const [devices, setDevices] = useState([]);
  const [traces, setTraces] = useState([]);

  const handleDevices = (response) => {
    let data = response.map((device) => ({
      deviceId: device.deviceId,
      name: device.name,
      category: device.category,
      type: device.type,
      status: device.status,
      device_order: device.device_order,
    }));

    data.sort((a, b) => a.device_order - b.device_order);

    setDevices(data);
  };

  const fetchDevices = () => {
    setShowLoading(true);

    let url = 'devices/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        handleDevices(response.data);
      }

      setShowLoading(false);
    });
  };

  const fetchTraces = () => {
    setShowLoading(true);

    let url = 'traces/list';
    let params = {};

    restGet(url, params, (response) => {
      if (response.exception) {
        setShowLoading(false);
        return setError('EXCEPTION');
      }

      if (response.success) {
        setTraces(response.data.reverse());
      }

      setShowLoading(false);
    });
  };

  useEffect(() => {
    fetchDevices();
    fetchTraces();

    socket.on('device_status_changed', (response) => {
      handleDevices(response.message);
      fetchTraces();
    });

    return () => socket.disconnect();
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.grid}>
        <Card sx={styles.card} raised>
          <CardContent>
            <div style={styles.cardTitle}>
              Iluminación
              <SpeechRecognition devices={devices} />
            </div>
            <div style={styles.cardBody}>
              {devices
                .filter((device) => device.category != 'alarm')
                .map((device, index) => (
                  <DeviceButton key={index} device={device} />
                ))}
            </div>
          </CardContent>
        </Card>

        <Card sx={styles.card} raised>
          <CardContent>
            <div style={styles.cardTitle}>Alarma</div>
            <div style={styles.cardBody}>
              {devices
                .filter((device) => device.category == 'alarm')
                .map((device, index) => (
                  <DeviceButton key={index} device={device} />
                ))}
            </div>
          </CardContent>
        </Card>

        <Card sx={styles.traceCard} raised>
          <CardContent>
            {traces.map((trace) => (
              <div>{trace}</div>
            ))}
          </CardContent>
        </Card>

        <Card sx={styles.card} raised>
          <CardContent>
            <div style={styles.cardTitle}>Clima</div>
            <div style={styles.cardBody}>
              <Weather />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Panel;
