import { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Context } from '../../context/Context';

const Loading = () => {
  const { showLoading } = useContext(Context);

  return (
    showLoading && (
      <div style={styles.background}>
        <div style={styles.circle}>
          <CircularProgress size={40} />
        </div>
      </div>
    )
  );
};

const styles = {
  background: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    background: '#ffffffc0',
  },
  circle: {
    marginTop: '40vh',
  },
};

export default Loading;
